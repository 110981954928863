import React from 'react';
import howWeWork from '../assets/images/how_we_work.png';

const HowItWorks = () => {
  const steps = [
    {
      id: 1,
      title: 'Ads Clicking',
      description: 'User clicks your ads via our dedicated link for your app'
    },
    {
      id: 2,
      title: 'App Downloading',
      description: 'User will be redirected to your app store page to download your app'
    },
    {
      id: 3,
      title: 'Smart Attribution',
      description: 'Advanced algorithms attribute user actions to the correct channels'
    },
    {
      id: 4,
      title: 'Optimization',
      description: 'Continuously improve your campaigns based on real data'
    }
  ];

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">How Ujourney Works</h2>
        <p className="text-center text-gray-600 mb-12">
          Our streamlined process makes it easy to track and optimize your iOS app marketing
        </p>
        
        <div className="mb-16 max-w-4xl mx-auto">
          <img 
            src={howWeWork} 
            alt="How Ujourney Works" 
            className="w-full h-auto rounded-xl shadow-2xl object-cover"
          />
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
          {steps.map((step) => (
            <div 
              key={step.id}
              className="bg-white p-8 rounded-xl shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className="flex items-center mb-4">
                <span className="w-10 h-10 bg-blue-500 text-white rounded-full flex items-center justify-center font-bold text-lg">
                  {step.id}
                </span>
                <h3 className="ml-4 font-semibold text-xl">{step.title}</h3>
              </div>
              <p className="text-gray-600 leading-relaxed">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks; 