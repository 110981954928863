import React from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { cn } from '../lib/utils';
import { ChevronDown, Menu, X } from 'lucide-react';

const navigationItems = [
  {
    title: 'Features',
    href: '#features',
    description: 'Explore our powerful features for iOS app tracking'
  },
  {
    title: 'Results',
    href: '#testimonials',
    description: 'See how we help businesses boost their ROI'
  },
  {
    title: 'Why Ujourney',
    href: '#why',
    description: 'Discover what makes us different'
  },
  {
    title: 'FAQ',
    href: '#faq',
    description: 'Get answers to common questions'
  }
];

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <nav className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container flex h-16 items-center">
        <div className="mr-4 flex items-center">
          <span className="text-2xl font-bold text-primary">Ujourney</span>
        </div>

        {/* Desktop Navigation */}
        <div className="hidden lg:flex lg:flex-1">
          <NavigationMenu.Root className="relative flex flex-1 justify-center">
            <NavigationMenu.List className="flex flex-1 list-none items-center justify-center space-x-1">
              {navigationItems.map((item) => (
                <NavigationMenu.Item key={item.title}>
                  <NavigationMenu.Trigger className="group inline-flex h-10 w-max items-center justify-center rounded-md bg-background px-4 py-2 text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50">
                    {item.title}
                    <ChevronDown
                      className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
                      aria-hidden="true"
                    />
                  </NavigationMenu.Trigger>
                  <NavigationMenu.Content className="absolute left-0 top-0 w-full data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out">
                    <div className="grid w-full gap-2 p-4 md:grid-cols-2">
                      <div className="p-6 hover:bg-muted rounded-lg">
                        <a href={item.href} className="focus:outline-none">
                          <div className="text-sm font-medium leading-none">{item.title}</div>
                          <p className="text-sm text-muted-foreground">{item.description}</p>
                        </a>
                      </div>
                    </div>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>
              ))}
            </NavigationMenu.List>
          </NavigationMenu.Root>
        </div>

        {/* CTA Button */}
        <div className="hidden lg:flex lg:items-center lg:justify-end lg:flex-1">
          <button className="inline-flex items-center justify-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground transition-colors hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
            Join Waitlist
          </button>
        </div>

        {/* Mobile Menu Button */}
        <div className="flex lg:hidden flex-1 justify-end">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="inline-flex items-center justify-center rounded-md p-2 text-primary hover:bg-accent hover:text-accent-foreground"
          >
            <span className="sr-only">Open main menu</span>
            {isMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="lg:hidden">
          <div className="space-y-1 px-2 pb-3 pt-2">
            {navigationItems.map((item) => (
              <a
                key={item.title}
                href={item.href}
                className="block rounded-md px-3 py-2 text-base font-medium text-foreground hover:bg-accent hover:text-accent-foreground"
                onClick={() => setIsMenuOpen(false)}
              >
                {item.title}
              </a>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar; 