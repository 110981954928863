import React from 'react';
import { TrendingDown, TrendingUp } from 'lucide-react';

const Testimonials = () => {
  return (
    <section className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Proven Results
          </h2>
        </div>
        <div className="mt-10">
          <div className="relative bg-white p-8 rounded-xl shadow-lg">
            <div className="text-center">
              <div className="grid grid-cols-2 gap-8 max-w-2xl mx-auto">
                <div className="flex flex-col items-center space-y-3">
                  <div className="w-16 h-16 rounded-full bg-blue-100 flex items-center justify-center">
                    <TrendingDown className="w-8 h-8 text-blue-600" />
                  </div>
                  <div className="text-2xl font-bold text-blue-600">50%</div>
                  <div className="text-sm text-gray-500">CPI Reduction</div>
                </div>
                <div className="flex flex-col items-center space-y-3">
                  <div className="w-16 h-16 rounded-full bg-green-100 flex items-center justify-center">
                    <TrendingUp className="w-8 h-8 text-green-600" />
                  </div>
                  <div className="text-2xl font-bold text-green-600">200%</div>
                  <div className="text-sm text-gray-500">ROI Increase</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials; 