import React from 'react';
import { motion } from 'framer-motion';
import aiIcon from '../assets/images/ai.png';

const features = [
  {
    title: 'Cross-Channel Tracking',
    description: 'Track user journeys accurately across all marketing channels',
    icon: '📊',
    color: 'bg-blue-500'
  },
  {
    title: 'Real-Time Reporting',
    description: 'Make faster decisions with instant performance insights',
    icon: '⚡',
    color: 'bg-purple-500'
  },
  {
    title: 'Deferred Deep Linking',
    description: 'Improve user experience with seamless app navigation',
    icon: '🔗',
    color: 'bg-indigo-500'
  },
  {
    title: 'AI-Powered Insights',
    description: 'Optimize your creatives with artificial intelligence',
    icon: <img src={aiIcon} alt="AI" className="w-8 h-8" />,
    color: 'bg-cyan-500'
  }
];

const Features = () => {
  return (
    <div className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-3xl mx-auto">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-base text-blue-600 font-semibold tracking-wide uppercase"
          >
            Features
          </motion.h2>
          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="mt-2 text-4xl font-bold text-gray-900 tracking-tight"
          >
            Everything you need to succeed
          </motion.p>
        </div>

        <div className="mt-20 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="relative bg-white p-6 rounded-2xl shadow-xl hover:shadow-2xl 
                         transition-shadow duration-300 border border-gray-100"
            >
              <div className={`${feature.color} w-12 h-12 rounded-full flex items-center 
                              justify-center text-2xl mb-4`}>
                {feature.icon}
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600">
                {feature.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features; 